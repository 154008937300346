<template>
    <div class="home">
         <ScheduleCalendar ></ScheduleCalendar>
        <p></p>
    </div>
</template>

<script>
// fix
// @ is an alias to /src
import ScheduleCalendar from '../views/admin/event/event-training/training-schedule/ScheduleCalendar';

export default {
    name       : 'Home',
    components : {
        ScheduleCalendar
    }
};

</script>
<style>
/* Style for the home page component */
</style>
